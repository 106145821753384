<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

import Multiselect from "vue-multiselect";
import axios from "axios";
/**
 * Form validation component
 */
export default {
  page: {
    title: "conseil",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Conseils passagers",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],
      conseilObj: {
        step: "",
        audioLink: "",
      },
      conseilObjModif: {
        step: "",
        audioLink: "",
      },
      conseilRowInfo: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      bonPlan: [],
      conseilActif: [],
      conseilDes: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "step", sortable: true, label: "Numero Étap" },
        { key: "audioLink", sortable: true, label: "Fichier audio" },
        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      totalconseil: 0,
      totalconseilInactif: 0,
      mediaRecorder: null,
      audioChunks: [],
      isRecording: false,
      audioBlob: null,
      stepSelected: null,

      stepList: [
        {
          name: "Détail sur identité",
          value: 1,
        },
        {
          name: "Photo et pièce d'identité",
          value: 2,
        },
        {
          name: "Choix des préférences",
          value: 3,
        },
        {
          name: "Recharge du portefeuille ",
          value: 4,
        },
        {
          name: "Recherche de trajet",
          value: 5,
        },
      ],

      recordingDuration: 0,

      mediaStream: null,
      timerInterval: null,
      totalBonPlan:null
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      const listconseil = await apiRequest(
        "GET",
        "audio-passager",
        undefined,
        false
      );

      if (listconseil && listconseil.data) {
       

        const conseilTAble = listconseil.data.map((conseil) => {
          return {
            id: conseil.id,
            step: conseil.step,
            audioLink: conseil.audioLink,
          };
        });
        this.conseilActif = conseilTAble;
        this.totalBonPlan = conseilTAble.length
      }
    },
    editRow(row) {
      this.showEdit = true;
      this.conseilObjModif.step = row.step;

      this.conseilObjModif.audioLink = row.audioLink;
    },
    desactiverRow(row) {
      this.showdisable = true;
      this.conseilRowInfo = row;
    },
    activerconseil(row) {
      this.showactive = true;
      this.conseilRowInfo = row;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.conseilRowInfo = row;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.formSubmit();
      this.$v.$touch();
    },

    // Audio recorder
    startRecording() {
      this.$refs.audioElement.src = "";
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.addEventListener("dataavailable", (event) => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data);
            }
          });
          this.mediaRecorder.start();
          this.isRecording = true;
          this.startTimer();
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
        });
    },

    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;
        setTimeout(() => {
          this.saveRecording();
        }, 2000);
        this.stopTimer();
      }
    },
    saveRecording() {
      const blob = new Blob(this.audioChunks, { type: "audio/mp3" });
      const url = URL.createObjectURL(blob);
      this.$refs.audioElement.src = url;
      this.audioBlob = blob;
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileUploadModif() {
      this.fileModif = this.$refs.files.files[0];
    },

    async submitconseilObj() {
      let formData = new FormData();
      formData.append("step", this.conseilObj.step.value);
      formData.append("audioLink", this.audioLink);
      const returnconseil = await apiRequest("POST", "conseil", formData, true);

      if (returnconseil ) {
        this.init();
        this.$toast.success("Audio ajouté avec succès !!!", {
  position: "bottom-right",
  timeout: 2000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
      }
    },
    async submitconseilObjModif() {
      let formData = new FormData();
      formData.append("step", this.conseilObjModif.step);
      formData.append("audioLink", this.conseilObjModif.audioLink);

      const returnconseilModif = await apiRequest(
        "PUT",
        "conseil",
        formData,
        true
      );

      if (returnconseilModif && returnconseilModif.data) {
        this.showEdit = false;
        this.init();
      }
    },
    async desactivePlan() {
      this.showdisable = false;
      const returnActualitDeactivate = await apiRequest(
        "POST",
        "conseil-off",
        { id: this.conseilRowInfo.id, visible: false },
        false
      );

      if (returnActualitDeactivate && returnActualitDeactivate.data) {
        this.showdisable = false;
        this.init();
      }
    },
    async activePlan() {
      const returnActualitActive = await apiRequest(
        "POST",
        "conseil-off",
        { id: this.conseilRowInfo.id, visible: true },
        false
      );

      if (returnActualitActive && returnActualitActive.data) {
        this.showactive = false;
        this.init();
      }
    },
    async deletePlan() {
      const returnActualitDelete = await apiRequest(
        "POST",
        "audio-passager-supp",
        { id: this.conseilRowInfo.id },
        false
      );
      if (returnActualitDelete && returnActualitDelete.data) {
        this.showDelete = false;

        this.$toast.success("Information supprimée avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.init();

      } else {
        this.$toast.error("Information non supprimée", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    uploadRecording() {
      if (this.audioBlob) {
        this.uploadImage(this.audioBlob);
      }
    },

    uploadImage(file) {
      const formData = new FormData();

      const title = `recorded_audio_${this.stepSelected.value}.mp3`;
      formData.append("audio", file, title);

      // Make an HTTP POST request to the server-side script
      axios
        .post("https://images.raynis.co/upload.php", formData)
        .then((response) => {
          // Handle the response from the server

          this.audioLink = response.data;

         
          if (this.audioLink.startsWith("https")) {
            let formData = new FormData();

            formData.append("step", this.stepSelected.value);
            formData.append("audioLink", this.audioLink);
            const returnBonPlan = apiRequest(
              "POST",
              "audio-passager",
              formData,
              true
            );

            if (returnBonPlan) {
             

              this.$toast.success("Audio ajouté avec succès", {
                position: "bottom-right",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              });
              this.init();
              this.$refs.audioElement.src=""
              this.audioLink=""
            }
          } else {
            this.init();
            this.$toast.success(
              "Audio non uploadé; Supprimé l'audio existant",
              {
                position: "bottom-right",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false,
              }
            );
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the upload
          console.error(error);
        });
    },

    startTimer() {
      this.recordingDuration = 0;
      this.timerInterval = setInterval(() => {
        this.recordingDuration += 1;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval);
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <form
              enctype="multipart/form-data"
              @submit.prevent="uploadRecording"
            >
              <div class="form-group">
                <label for="title">Step </label>
                <multiselect
                  v-model="stepSelected"
                  :options="stepList"
                  label="name"
                  track-by="value"
                ></multiselect>
              </div>

              <div class="form-group">
                <label for="file">Audio</label>
                <!--   <input
                  type="file"
                  id="file"
                  ref="file"
                  v-on:change="handleFileUpload"
                  class="form-control"
                /> -->

                <div class="space-record">
                  <button
                    :style="
                      isRecording ? { color: '#D3D3D3', border: 'none' } : {}
                    "
                    class="start-btb"
                    @click="startRecording"
                    :disabled="isRecording"
                    @click.prevent="startRecording"
                  >
                    Démarrer
                    <ion-icon
                      :style="isRecording ? { color: '#D3D3D3' } : {}"
                      name="mic-outline"
                    ></ion-icon>
                  </button>

                  <button
                    @click.prevent="stopRecording"
                    class="end-btb"
                    :disabled="!isRecording"
                  >
                    Arrêter <ion-icon name="stop-circle-outline"></ion-icon>
                  </button>
                  <audio ref="audioElement" controls></audio>
                </div>
              </div>
              <div class="form-group">
                <button
                  :disabled="!audioBlob"
                  class="btn text-success-save float-right"
                  type="submit"
                >
                  Ajouter
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Gérer</h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">conseil actives</a>
                  </template>
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-6">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>

                    <div class="col-sm-12 col-md-6">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-right"
                      >
                        <label class="d-inline-flex align-items-center">
                          Rechercher:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            class="form-control form-control-sm ml-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="conseilActif"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <!--  <a
                          href="javascript:void(0);"
                          class=" text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="desactiverRow(row.item)"
                          title="Désactiver"
                        >
                          <i class="mdi mdi-close font-size-18"></i>
                        </a> -->

                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="deleteRow(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalBonPlan"
                            :per-page="perPage"
                            :rows="conseilActif"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab>

                <!--    <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active"
                      >Liste des conseils inactives</a
                    >
                  </template>
                  <div class="row  d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>

                    <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>
            
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="conseilDes"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                        <a
                          href="javascript:void(0);"
                          class=" text-primary"
                          v-b-tooltip.hover
                          @click="editRow(row.item)"
                          title="Editer"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="text-success"
                          v-b-tooltip.hover
                          @click="activerconseil(row.item)"
                          title="Activer"
                        >
                          <i class="mdi mdi-check font-size-18"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="deleteRow(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">

                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalconseil"
                            :per-page="perPage"
                            :rows="bonPlanDes"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </b-tab> -->
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer cette information ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletePlan"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showdisable"
      title="Voulez-vous vraiment désactiver ce bon plan ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactivePlan"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showactive"
      title="Voulez-vous vraiment activer bon plan ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showactive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activePlan"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <!--     <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >
      <form
        enctype="multipart/form-data"
        @submit.prevent="submitconseilObjModif"
      >
        <div class="form-group">
          <label for="tite">Titre</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="titre"
            v-model="conseilObjModif.title"
          />
        </div>
       
        <div class="form-group">
          <label for="tite">texte</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="sous titre"
            v-model="conseilObjModif.text"
          />
        </div>
      
        <div class="form-group">
          <label for="title">Image</label>
          <input
            type="file"
            id="files"
            ref="files"
            v-on:change="handleFileUploadModif.file"
            class="form-control"
          />
        </div>

        <div class="form-group">
          <label for="tite">Site web</label>
          <input
            type="text"
            id="site"
            class="form-control"
            placeholder="Exemple : http://exemple.com"
            v-model="conseilObjModif.web"
          />
        </div>
       
        <div class="form-group">
          <b-button variant="danger" @click="showEdit = false">Annuler</b-button>
          <button class="btn text-success-save float-right">Modifier</button>

        </div>
      
       
      </form>
    </b-modal> -->
  </Layout>
</template>

<style>
#showPassword {
  width: 35px;
  height: 35px;
}

thead {
  background: #3aaa35;
}
thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.text-success-save {
  background: #3aaa35;
  outline: none;
  border: none;
  color: #fff;
}
.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3aaa35;
  border-color: #3aaa35;
}
.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}
.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}
.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}
.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3aaa35 !important;
  border-bottom: 1px solid #000000 !important;
}
.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}

.end-btb {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.space-record {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.start-btb {
  font-size: 28px;
  vertical-align: middle;
  border-radius: 20px;
  color: #3880ff;
  border: 1px solid #3880ff;
}

.end-btb {
  font-size: 28px;
  vertical-align: middle;
  border-radius: 20px;
  color: #ff0000;
  border: 1px solid #ff0000;
}

.end-btb ion-icon {
  margin-bottom: -9px;
  margin-bottom: -5px;
  font-size: 30px;
}

.start-btb ion-icon {
  margin-bottom: -5px;
  font-size: 30px;
}
</style>
